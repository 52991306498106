// Bio.js

import React from 'react';
import { useSpring, animated } from '@react-spring/web'
import "./Bio.css";
import {motion} from 'framer-motion';
import sample from "../images/design.png"

const convertNewlinesToBreaks = (text) => {
  return text.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));
};

function Bio({greek}){
    const greekText = "Δημιουργικό γραφείο, με εμπειρία και συνεχή παρουσία στον χώρο των γραφικών τεχνών από το 1998. \n\n"+
"Η καλλιτεχνική προσέγγιση του γραφείου, πάνω στις δικές σας μοναδικές ανάγκες, οδηγεί σε πρωτότυπες λύσεις, φρέσκιες ιδέες και ολοκληρωμένες προτάσεις, οι οποίες συνδυάζουν την αισθητική, με το άρτιο, τεχνικά, αποτέλεσμα και την εγγυημένη ποιότητα. \n\n"+
"Προτεραιότητά μας, η καλύτερη δυνατή επικοινωνία και προβολή για την επιχείρηση, το project ή την επαγγελματική σας ταυτότητα. \n\n"+
"Οι υπηρεσίες ξεκινούν από το αρχικό στάδιο του σχεδιασμού, μέχρι και το τελικό στάδιο της εκτύπωσης/ δημοσίευσης στο διαδίκτυο.";

    const englishText = "Creative office, with experience and continuous presence in graphic arts since 1998. \n\n"+
    "The office’s artistic approach to your own personal needs leads to original solutions, fresh ideas, and complete proposals, which combine aesthetics, with excellent technical results and guaranteed quality. \n\n"+
    "Our priority is the best possible communication and exposure for your business, project, or professional identity. \n\n"+
    "The services begin from the initial design stage, up until the final stage of printing/publication on the web. ";
    

    return (
      <div>
      <div className="pageBody"/>
        <br/> <br/>
      <div></div>
    <motion.div initial={{opacity:0}} animate={{opacity:100, transition: { duration: 0.8, delay:0.5 , ease: "easeInOut"}}} exit={{opacity:0, x:'-100%', transition: { duration: 0.2, ease: "easeInOut" }}}>
   

      <div className='container-fluid'> 

        <div className='container-fluid'>
          <motion.div initial={{x:"-30vw"}} animate={{x:0  ,transition: { duration: 0.8, ease: "backInOut", delay:0.5 }}}>
                  
                  <div className="text-bottom">
                    <div className='fw-light text-top'>E<span className='h5 fw-light'>leni</span> K<span className='h5 fw-light'>ourkounaki Creative Studio</span></div>
                    <hr/>
                    <div className='row'>
                      <div className='col-6'>
                        <p className="fw-light" style={{ fontWeight: 100 }}>
                        {greek ? convertNewlinesToBreaks(greekText) : convertNewlinesToBreaks(englishText)}
                        </p>
                      </div>
                      <div className='col-6'> 
                          <img src={sample} className='half-photo'></img>
                      </div>
                    </div>
                  </div>
               
          </motion.div>
        </div>

      </div>
    </motion.div>
    <br/><br/><br/>
    </div>
    );
  }
export default Bio;
import React, { Component } from 'react';
import "./Home.css";
import logo from '../images/logos/logo.jpg';
import idea from '../images/idea.png';
import slide1 from '../images/Carousel_slide_1.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward } from '@fortawesome/free-solid-svg-icons';

import {motion} from 'framer-motion';

function Home({greek}){  

  document.addEventListener('scroll', function() {
    const ideaIcon = document.querySelector('.idea-icon');

    if (!ideaIcon) return;
    const scrollPosition = window.scrollY; // Get the current scroll position
    // const ideaIcon = document.querySelector('.idea-icon');

    // Adjust the top position based on the scroll position
    // The divisor (e.g., 2) controls the parallax effect strength
    ideaIcon.style.top = `${scrollPosition * 0.5}px`; // Adjust this factor as needed


    const colorChangeElements = document.querySelectorAll('.color-change'); // Select all elements

    // Change color based on scroll position
    // Example: Change color from blue to red based on scroll
    const maxScroll = 500; // Maximum scroll threshold for color change
     // Check if scroll position exceeds maxScroll
     if (scrollPosition > 700) {
      window.scrollTo(0, 700); // Reset scroll position to maxScroll
    }
    const ratio = Math.min(scrollPosition / maxScroll, 1); // Normalize the scroll ratio (0 to 1)

    var i=1;
    // Loop through each element to update its color
    colorChangeElements.forEach(function(element) {
      // Interpolate color change from blue (rgb(0, 0, 255)) to red (rgb(255, 0, 0))
      const red = Math.floor(0 * (1 - ratio) + 255 * ratio);
      const green = Math.floor(0 * (1 - ratio) + 0 * ratio);
      const blue = Math.floor(255 * (1 - ratio) + 0 * ratio);

      // Set the new color
      element.style.opacity = 1 - scrollPosition / (300 / i);
      i++;
  });
});

const carouselPhotos = [slide1];

    return (
    <div> 

      <div className='pageBody'/>
      <motion.div className='row align-items-center' initial={{"opacity":0}} animate={{"opacity":1  ,transition: { duration: 1, ease: "easeInOut", delay:0 }}} exit={{"opacity":0  ,transition: { duration: 0.4, ease: "easeInOut", delay:0 }}}>
        <img key={`home_img`} src={idea} className="idea-icon" alt="..."/>
      </motion.div>
      <br/> 
        <motion.div className='m-auto' initial={{"width":"0", "opacity":0, rotateX:"180deg"}} animate={{"width":"90vw","opacity":1, rotateX:"0deg"  ,transition: { duration: 1.3, ease: "backInOut", delay:0.2 }}} exit={{"x":"50vw","opacity":0  ,transition: { duration: 0.4, ease: "easeInOut", delay:0 }}}>
          <div className='container-fluid gray-container m-auto' style={{"height" : "auto", "alignContent":"center"}}>
      
            <motion.div className='row align-items-center' initial={{"opacity":0}} animate={{"opacity":1  ,transition: { duration: 1, ease: "easeInOut", delay:1 }}} exit={{"opacity":0  ,transition: { duration: 0.4, ease: "easeInOut", delay:0 }}}>
              
              <div className='col img-container m-auto'>
                
                <motion.div id='column2' className="row" initial={{opacity:0, scale:0}} animate={{opacity:100 , scale:1 ,transition: { duration: 0.6, ease: "backOut", delay:0.6 }}} exit={{opacity:0,  transition: { duration: 0.5, ease: "backOut" }}} >
                    <div className="content-wrapper">
                      <br/>
                      <motion.div className='row align-items-center' initial={{"opacity":0}} animate={{"opacity":1  ,transition: { duration: 1, ease: "easeInOut", delay:1 }}} exit={{"opacity":0  ,transition: { duration: 0.4, ease: "easeInOut", delay:0 }}}>
                        <div className='h4 fw-light'>
                    
                          {!greek?"Creative office, with experience and continuous presence in graphic arts since 1998 ":"Δημιουργικό γραφείο, με εμπειρία και συνεχή παρουσία στον χώρο των γραφικών τεχνών από το 1998 "}
                          <FontAwesomeIcon icon={faAward}/>
                          
                        </div>
                        
                        <br/>
                        <hr/>
                      </motion.div>
                      <div id="carousel" className="carousel size-class slide home-screen">
                        <div className="carousel-indicators">
                          { 
                          carouselPhotos?.map((photo, index) => {
                            return(
                                <button key={`photo_shortcut_${index}`} type="button" data-bs-target="#carousel" data-bs-slide-to={index} className={`${index===0&&'active'} dot`} aria-current={`page`} aria-label={`Slide ${index}`}></button>
                                )})
                          }
                        </div>
                        <div className="carousel-inner">
                          {carouselPhotos?.map((photo, index) => {
                                          return(
                                              <div className={`carousel-item ${index===0&&'active'}`} key={`carousel-item-${index}`}>
                                                  <div className="image-container" style={{ position: "relative", overflow: "hidden" }}>
                                                  <motion.img key={`photo_${index}`} initial={{opacity:0, scale:0, rotateZ:"30deg"}} animate={{opacity:100 , scale:1, rotateZ:"0deg",transition: { duration: 0.8, ease: "easeIn", delay:0.3 }}} exit={{opacity:0,  transition: { duration: 0.2, ease: "easeInOut" }}} id={`photo_${index}`} className='d-block w-100 my-image' src={photo}></motion.img>
                                                  </div>
                                              </div>)})
                                          }
                          </div>
                          <button className="carousel-control-prev" type="button" data-bs-target="#carousel" data-bs-slide="prev">
                              {/* <span className="carousel-control-prev-icon" aria-bs-hidden="true">prev</span> */}
                              <span className="btn btn-lg btn-carousel carousel-prev">{"<"}</span>
                          </button>
                          <button className="carousel-control-next" type="button" data-bs-target="#carousel" data-bs-slide="next">
                              {/* <span className="carousel-control-next-icon" aria-bs-hidden="true">next</span> */}
                              <span className="btn btn-lg btn-carousel carousel-next">{">"}</span>
                          </button>
                        </div>
                    </div>
                </motion.div>
              </div>
            </motion.div>

            <br/>
            <br/>

          </div>
        </motion.div>
        <br/>
      

        <motion.div className='m-auto color-change' initial={{"width":"0", "opacity":0, rotateX:"220deg"}} animate={{"width":"80vw","opacity":1, rotateX:"0deg"  ,transition: { duration: 1, ease: "backInOut", delay:0.7 }}} exit={{"x":"50vw","opacity":0  ,transition: { duration: 0.4, ease: "easeInOut", delay:0 }}}>
          <div className='container-fluid gray-container' style={{"height" : "5vh"}}>
            
          </div>
        </motion.div>

        <br/>

        <motion.div className='m-auto color-change' initial={{"width":"0", "opacity":0, rotateX:"300deg"}} animate={{"width":"70vw","opacity":1, rotateX:"0deg"   ,transition: { duration: 1, ease: "backInOut", delay:0.8 }}} exit={{"x":"50vw","opacity":0  ,transition: { duration: 0.4, ease: "easeInOut", delay:0.1 }}}>
          <div className='container-fluid gray-container' style={{"height" : "4vh"}}>
            
          </div>
        </motion.div>

        <br/>

        <motion.div className='m-auto color-change ' initial={{"width":"0", "opacity":0, rotateX:"320deg"}} animate={{"width":"55vw","opacity":1 , rotateX:"0deg" ,transition: { duration: 1, ease: "backInOut", delay:0.9 }}} exit={{"x":"50vw","opacity":0  ,transition: { duration: 0.4, ease: "easeInOut", delay:0.15 }}}>
          <div className='container-fluid gray-container' style={{"height" : "3vh"}}>
            
          </div>
        </motion.div>

        <br/>

        <motion.div className='m-auto color-change ' initial={{"width":"0", "opacity":0, rotateX:"320deg"}} animate={{"width":"40vw","opacity":1 , rotateX:"0deg" ,transition: { duration: 1, ease: "backInOut", delay:1 }}} exit={{"x":"50vw","opacity":0  ,transition: { duration: 0.4, ease: "easeInOut", delay:0.2 }}}>
          <div className='container-fluid gray-container' style={{"height" : "2vh"}}>
            
          </div>
        </motion.div>

        <br/>

        <motion.div className='m-auto color-change ' initial={{"width":"0", "opacity":0, rotateX:"360deg"}} animate={{"width":"25vw","opacity":1 , rotateX:"0deg" ,transition: { duration: 1, ease: "backInOut", delay:1.1 }}} exit={{"x":"50vw","opacity":0  ,transition: { duration: 0.4, ease: "easeInOut", delay:0.25 }}}>
          <div className='container-fluid gray-container' style={{"height" : "1vh"}}>
            
          </div>
        </motion.div>
        <br/>
        <motion.div className='m-auto color-change ' initial={{"width":"0", "opacity":0, rotateX:"400deg"}} animate={{"width":"5vw","opacity":1  , rotateX:"0deg",transition: { duration: 1, ease: "backInOut", delay:1.2 }}} exit={{"x":"50vw","opacity":0  ,transition: { duration: 0.4, ease: "easeInOut", delay:0.3 }}}>
          <div className='container-fluid gray-container' style={{"height" : "0.1vh"}}>
            
          </div>
        </motion.div>

    </div>
    );
  }
export default Home;
// Bio.js

import { useState } from "react";
import React from 'react';
import "./Services.css";
import {motion} from 'framer-motion';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle} from '@fortawesome/free-solid-svg-icons';



function Services({greek}){
  const [category, setCategory] = useState("");
  const [isHovered, setIsHovered] = useState(false);

  // CATEGORIES STUFF
  const categories = [
    {"en":"","gr":""},
    {"en":"Branding", "gr":"Εταιρική Ταυτότητα"},
    {"en":"Books", "gr":"Βιβλία"},
    {"en":"Brochures", "gr":"Έντυπα"},
    {"en":"Posters", "gr":"Αφίσες"},
    {"en":"Advertising", "gr":"Καταχωρίσεις"},
    {"en":"Packaging", "gr":"Συσκευασίες"},
    {"en":"Promotional", "gr":"Προωθητικά είδη"},
    {"en":"Invitations", "gr":"Προσκλήσεις"},
    {"en":"Outdoor Design", "gr":"Εξωτερική Διαφήμιση"},
    {"en":"Car Wrapping", "gr":"Ντύσιμο Οχημάτων"},
    {"en":"Media & Animation", "gr":"Media & Animation"},
    {"en":"Social", "gr":"Social"},
    {"en":"Exhibition Design", "gr":"Σχεδιασμός Εκθεσιακών Περιπτέρων"}
  ]

  const categoriesDescriptions = [
    {"en":"","gr":""},
    {"en":"It’s the optical representation (symbol or design or letters), which combined with the right colors, constitutes the defining element of the visual identity of a brand.", "gr":"Είναι η οπτική αναπαράσταση (σύμβολο ή σχέδιο ή γράμματα) που σε συνδυασμό με τα κατάλληλα χρώματα αποτελεί το καθοριστικό στοιχείο της οπτικής ταυτότητας μίας επωνυμίας."},
    {"en":"Multiple book kinds (literature, kids, science etc.) Each one of which demands its own graphic approach, to achieve the best possible response for its’ target audience. ", "gr":"Υπάρχουν πολλά είδη βιβλίου (λογοτεχνικό, παιδικό, επιστημονικό κλπ). Το κάθε ένα από αυτά απαιτεί τη δική του γραφιστική προσέγγιση ώστε να έχει την καλύτερη δυνατή ανταπόκριση στο κοινό που απευθύνεται."},
    {"en":"Multiple-page copies and flyers folded or single view, through their design aim towards promoting or informing for advertising, commercial or informative purposes.", "gr":"Τα πολυσέλιδα έντυπα και τα φυλλάδια, διπλωμένα ή μονόφυλλα, με το σχεδιασμό τους στοχεύουν στην προώθηση ή την πληροφόρηση για διαφημιστικούς, εμπορικούς ή ενημερωτικούς σκοπούς. "},
    {"en":"Destined to attract the attention of audiences and to inform about cultural, social etc. event, while in intelligent and aesthetic way, providing the necessary information about the time and the place its being conducted in.", "gr":"Προορίζονται να τραβήξουν την προσοχή του κοινού και να πληροφορήσουν για κάποιο γεγονός πολιτιστικό, κοινωνικό κλπ. ενώ με έξυπνο και καλαίσθητο τρόποθα πρέπει παρέχονται οιαπαραίτητες πληροφορίες για τον τόπο και τον χρόνο διεξαγωγής του."},
    {"en":"Constitutes a means of exposure of a brand, a product, an event etc. It could be printed or digital and it’s effective when the creative design, in combination with the right message, gathers response from the target audience.", "gr":"Αποτελεί το μέσο προβολής μίας επωνυμίας, ενός προϊόντος, μίας εκδήλωσης κλπ. Μπορεί να είναι έντυπη ή ηλεκτρονική και είναι αποτελεσματική όταν ο δημιουργικός σχεδιασμός της σε συνδυασμό με το κατάλληλο μήνυμα βρίσκει ανταπόκριση στο κοινό που απευθύνεται."},
    {"en":"Product packaging, when creatively designed and clear in its information, can become a recognizable element, serving as a communication tool with the audience.", "gr":"Η συσκευασία ενός προϊόντος με τον κατάλληλο δημιουργικό σχεδιασμό και τη σαφήνεια στην πληροφόρηση που παρέχει μπορεί να αποτελέσει στοιχείο αναγνωρισιμότητας  και γι’ αυτό θεωρείται και εργαλείο επικοινωνίας με το κοινό. "},
    {"en":"A dynamic way of product promotion, is through advertising gifts that include among other things- the branding and relevant recognition elements. The range of advertising gifts is endless (t-shirts, umbrellas, calendars, pens etc.)", "gr":"Ένας δυναμικός τρόπος προώθησης προϊόντων είναι μέσω διαφημιστικών δώρων που θα φέρουν,εκτός των άλλων, την επωνυμία ή και συναφή αναγνωρίσιμα στοιχεία. Το εύρος των διαφημιστικών δώρων είναι ανεξάντλητο (t-shirts, ομπρέλα, ημερολόγιο, στυλό κ.λπ.)"},
    {"en":"Important events, such as weddings, baptisms, conferences etc., through a pleasant design, can inform the appropriate audience. They can be made into both printable and digital forms.", "gr":"Σημαντικά γεγονότα, όπως γάμοι, βαφτίσεις, συνέδρια ή εκδηλώσεις, μπορούν με έναν ευχάριστο σχεδιασμό να πληροφορήσουν κατάλληλα το κοινό που απευθύνονται. Μπορούν να γίνουν σε έντυπη ή και σε ηλεκτρονική μορφή."},
    {"en":"A large-scale outdoor advertising campaign designed for high-visibility locations (e.g., street banners, stadium displays, building wraps, transit stations) with the goal of reaching a broad and diverse audience on a massive scale.", "gr":"Μεγάλου μεγέθους διαφημιστική καμπάνια που προορίζεται για εξωτερικό χώρο (π.χ. πανό σε δρόμο, γήπεδο, κτίριο, στάση μεταφορικού μέσου κλπ.) και στόχο έχει να προσεγγίσει ένα πολύ μεγαλύτερο αριθμητικά κοινό. "},
    {"en":"The wrapping of parts or a whole vehicle with graphic elements, aimed towards the commercial promotion of products, brands, companies. The moving vehicle constitutes an on-the-road advertisement.", "gr":"Η επένδυση ολόκληρου ή μέρους οχήματος με γραφιστικά στοιχεία με στόχο την εμπορική προώθηση προϊόντων, μάρκας, εταιρείας. Το κινούμενο όχημα αποτελεί κινούμενη διαφήμιση."},
    {"en":"Short duration videos could include a motion, aimed towards attracting the audience’s attention for commercial or informative or other purposes. It is aimed at the audience’s attention for commercials for informative or other purposes. It is aimed towards digital use or led advertisements in stadiums etc.", "gr":"Video μικρής χρονικής διάρκειας που μπορεί να περιλαμβάνει και κίνηση με στόχο να τραβήξει την προσοχή του κοινού για εμπορικούς ή ενημερωτικούς ή και άλλουςσκοπούς. Προορίζεται για διαδικτυακή χρήση ήledδιαφημίσεις σε γήπεδα κλπ."},
    {"en":"Graphic design for uploading on social media, aimed towards informing of an audience, about a product, an event, a wish etc.", "gr":"Γραφιστικός σχεδιασμός για ανάρτηση στα μέσα κοινωνικής δικτύωσης με στόχο την ενημέρωση του κοινού για κάποιο προϊόν, εκδήλωση, ευχή κλπ."},
    {"en":"The idea and study of graphic design for an exhibition stand aimed towards showcasing and promoting the company, that owns it.", "gr":"Η ιδέα και η μελέτη του γραφιστικού σχεδιασμού ενός περιπτέρου έκθεσης με στόχο την ανάδειξη και προβολή της εταιρείας που το έχει."}
  ]


  const findCategoryIndexByName = (name) => {
    for (let i = 0; i < categories.length; i++) {
      // console.log("in loop categories[i].en.toUpperCase()=", categories[i].en.toUpperCase() , "englishName=", englishName);
        if (categories[i].en.toUpperCase() === name.toUpperCase()) {
            return i;
        }
        if (categories[i].gr.toUpperCase() === name.toUpperCase()) {
          return i;
      }
    }
    console.error("Category not found -", name);
    return -1; // If category is not found
  }

  const getCategory = (index) => {
    if (index < categories.length && index >= 0){
        if (!greek)return categories[index].en;
        else return categories[index].gr;
    }
    else if(index===-1){
        console.error("Category array index not found");
    }
    else{
        console.error("Category array index out of bounds");
    }
  }

  const getCategoryInfo = (index) => {
    if (index < categories.length && index >= 0){
        if (!greek)return categoriesDescriptions[index].en;
        else return categoriesDescriptions[index].gr;
    }
    else if(index===-1){
        console.error("Category array index not found");
    }
    else{
        console.error("Category array index out of bounds");
    }
  }

  const getEnglishCategory = (index) => {
    if (index < categories.length && index >= 0){
        return categories[index].en;
    }
    else if(index===-1){
        console.error("Category array index not found");
    }
    else{
        console.error("Category array index out of bounds");
    }
  }

  const handleHover = (e)=>{
    // console.log(e.target.textContent);
    setCategory(e.target.textContent);
    setIsHovered(true);
  }

    return (
      <div>
      <div className="pageBody"/>
      <div className={`blackOverlay ${isHovered ? 'darkened' : ''}`}></div>

      <motion.div id='column1' className={`services-overlay ${isHovered&&"services-details"}`} initial={{x:"20vw"}} animate={{x:0, transition: { duration: 0.4, delay:0 , ease: "backInOut"}}} exit={{opacity:0,  transition: { duration: 1, ease: "backOut" }}}>
          <div className="content-wrapper justify-content-center">
              <FontAwesomeIcon icon={faInfoCircle} size='2x' />
              <div className='h5 fw-normal txt-content content-title'>{getCategory(findCategoryIndexByName(category))}<hr/></div>
              <div className='fw-light txt-content content-title'>{getCategoryInfo(findCategoryIndexByName(category))}</div>
          </div>
      </motion.div>

      
      
      <div>
      <motion.div initial={{opacity:0}} animate={{opacity:100,scale:1, transition: { duration: 0.8,  delay:0.2 , ease: "easeInOut"}}} exit={{opacity:0,scale:0, transition: { duration: 0.3, ease: "easeInOut" }}}>
        <br/>
        <motion.div initial={{x:"50vw"}} animate={{x:0  ,transition: { duration: 0.8, ease: "backInOut", delay:0.3 }}}>
            <div className='h1 fw-light text-decoration'>{!greek?"Services we provide":"Υπηρεσίες που παρέχουμε"}</div>
            <div className='h5 fw-light text-decoration'>{!greek?"Click to see projects of the selected category!":"Επιλέξτε κατηγορία και δείτε σχετικά έργα!"}</div>
        </motion.div>
        <br/><br/>
        <div className='container-fluid services-container'> 
          <motion.div initial={{opacity:0, x:"30vw"}} animate={{opacity:100, x:0 ,transition: { duration: 0.7, ease: "backOut", delay:0.5 }}}>
            <Link to='/projects?category=Branding' style={{color : "inherit", textDecoration:"none"}}><motion.div className='h2 fw-light text-decoration fa-flip category-option' onHoverStart={handleHover} onHoverEnd={() => setIsHovered(false)}>{!greek?"Branding":"Εταιρική Ταυτότητα"}</motion.div></Link>
          </motion.div>
          <motion.div initial={{opacity:0, x:"-30vw"}} animate={{opacity:100, x:0 ,transition: { duration: 0.7, ease: "backOut", delay:0.55 }}}>
            <Link to='/projects?category=Books' style={{color : "inherit", textDecoration:"none"}}><motion.div className='h2 fw-light text-decoration fa-flip category-option' onHoverStart={handleHover} onHoverEnd={() => setIsHovered(false)}>{!greek?"Books":"Βιβλία"}</motion.div></Link>
          </motion.div>
          <motion.div initial={{opacity:0, x:"30vw"}} animate={{opacity:100, x:0 ,transition: { duration: 0.7, ease: "backOut", delay:0.6 }}}>
            <Link to='/projects?category=Brochures' style={{color : "inherit", textDecoration:"none"}}><motion.div className='h2 fw-light text-decoration fa-flip category-option' style={{"--fa-animation-delay":"0.1s"}} onHoverStart={handleHover} onHoverEnd={() => setIsHovered(false)}>{!greek?"Brochures":"Έντυπα"}</motion.div></Link>
          </motion.div>
          <motion.div initial={{opacity:0, x:"-30vw"}} animate={{opacity:100, x:0 ,transition: { duration: 0.7, ease: "backOut", delay:0.65 }}}>
            <Link to='/projects?category=Posters' style={{color : "inherit", textDecoration:"none"}}><motion.div className='h2 fw-light text-decoration fa-flip category-option' style={{"--fa-animation-delay":"0.1s"}} onHoverStart={handleHover} onHoverEnd={() => setIsHovered(false)}>{!greek?"Posters":"Αφίσες"}</motion.div></Link>
          </motion.div>
          <motion.div initial={{opacity:0, x:"30vw"}} animate={{opacity:100, x:0 ,transition: { duration: 0.7, ease: "backOut", delay:0.7 }}}>
            <Link to='/projects?category=Advertising' style={{color : "inherit", textDecoration:"none"}}><motion.div className='h2 fw-light text-decoration fa-flip category-option' style={{"--fa-animation-delay":"0.2s"}} onHoverStart={handleHover} onHoverEnd={() => setIsHovered(false)}>{!greek?"Advertising":"Καταχωρίσεις"}</motion.div></Link>
          </motion.div>
          <motion.div initial={{opacity:0, x:"-30vw"}} animate={{opacity:100, x:0 ,transition: { duration: 0.7, ease: "backOut", delay:0.75 }}}>
            <Link to='/projects?category=Packaging' style={{color : "inherit", textDecoration:"none"}}><motion.div className='h2 fw-light text-decoration fa-flip category-option' style={{"--fa-animation-delay":"0.2s"}} onHoverStart={handleHover} onHoverEnd={() => setIsHovered(false)}>{!greek?"Packaging":"Συσκευασίες"}</motion.div></Link>
          </motion.div>
          <motion.div initial={{opacity:0, x:"30vw"}} animate={{opacity:100, x:0 ,transition: { duration: 0.7, ease: "backOut", delay:0.8 }}}>
            <Link to='/projects?category=Promotional' style={{color : "inherit", textDecoration:"none"}}><motion.div className='h2 fw-light text-decoration fa-flip category-option' style={{"--fa-animation-delay":"0.3s"}} onHoverStart={handleHover} onHoverEnd={() => setIsHovered(false)}>{!greek?"Promotional":"Προωθητικά είδη"}</motion.div></Link>
          </motion.div>
          <motion.div initial={{opacity:0, x:"-30vw"}} animate={{opacity:100, x:0 ,transition: { duration: 0.7, ease: "backOut", delay:0.85 }}}>
            <Link to='/projects?category=Invitations' style={{color : "inherit", textDecoration:"none"}}><motion.div className='h2 fw-light text-decoration fa-flip category-option' style={{"--fa-animation-delay":"0.3s"}} onHoverStart={handleHover} onHoverEnd={() => setIsHovered(false)}>{!greek?"Invitations":"Προσκλήσεις"}</motion.div></Link>
          </motion.div>
          <motion.div initial={{opacity:0, x:"30vw"}} animate={{opacity:100, x:0 ,transition: { duration: 0.7, ease: "backOut", delay:0.7 }}}>
            <Link to='/projects?category=Outdoor Design' style={{color : "inherit", textDecoration:"none"}}><motion.div className='h2 fw-light text-decoration fa-flip category-option' style={{"--fa-animation-delay":"0.4s"}} onHoverStart={handleHover} onHoverEnd={() => setIsHovered(false)}>{!greek?"Outdoor Design":"Εξωτερική Διαφήμιση"}</motion.div></Link>
          </motion.div>
          <motion.div initial={{opacity:0, x:"-30vw"}} animate={{opacity:100, x:0 ,transition: { duration: 0.7, ease: "backOut", delay:0.7 }}}>
            <Link to='/projects?category=Car Wrapping' style={{color : "inherit", textDecoration:"none"}}><motion.div className='h2 fw-light text-decoration fa-flip category-option' style={{"--fa-animation-delay":"0.4s"}} onHoverStart={handleHover} onHoverEnd={() => setIsHovered(false)}>{!greek?"Car Wrapping":"Ντύσιμο Οχημάτων"}</motion.div></Link>
          </motion.div>
          <motion.div initial={{opacity:0, x:"30vw"}} animate={{opacity:100, x:0 ,transition: { duration: 0.7, ease: "backOut", delay:0.75 }}}>
            <Link to='/projects?category=Media and Animation' style={{color : "inherit", textDecoration:"none"}}><motion.div className='h2 fw-light text-decoration fa-flip category-option' style={{"--fa-animation-delay":"0.5s"}} onHoverStart={handleHover} onHoverEnd={() => setIsHovered(false)}>{!greek?"Media & Animation":"Media & Animation"}</motion.div></Link>
          </motion.div>
          <motion.div initial={{opacity:0, x:"-30vw"}} animate={{opacity:100, x:0 ,transition: { duration: 0.7, ease: "backOut", delay:0.75 }}}>
            <Link to='/projects?category=Social' style={{color : "inherit", textDecoration:"none"}}><motion.div className='h2 fw-light text-decoration fa-flip category-option' style={{"--fa-animation-delay":"0.5s"}} onHoverStart={handleHover} onHoverEnd={() => setIsHovered(false)}>{!greek?"Social":"Social"}</motion.div></Link>
          </motion.div>
          <motion.div initial={{opacity:0, x:"30vw"}} animate={{opacity:100, x:0 ,transition: { duration: 0.7, ease: "backOut", delay:0.8 }}}>
            <Link to='/projects?category=Exhibition Design' style={{color : "inherit", textDecoration:"none"}}><motion.div className='h2 fw-light text-decoration fa-flip category-option' style={{"--fa-animation-delay":"0.5s"}} onHoverStart={handleHover} onHoverEnd={() => setIsHovered(false)}>{!greek?"Exhibition Design":"Σχεδιασμός Εκθεσιακών Περιπτέρων"}</motion.div></Link>
          </motion.div>
        </div>
      </motion.div>
      </div>
      <br/> <br/> <br/> <br/><br/><br/><br/><br/>
      </div>
    );
  }
export default Services;
import React from 'react'
import Home from './Home';
import Bio from './Bio';
import Projects from './Projects';
import Clients from './Clients';
import Contact from './Contact';
import Services from './Services';
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from 'react-router-dom';
import {AnimatePresence} from 'framer-motion';

function AnimatedRoutes({greek}) {
    const location  = useLocation();
    return (
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                <Route exact path='/' element={<Home greek={greek}/>}> </Route>
                <Route path='/bio' element={<Bio greek={greek}/>} />
                <Route path='/services' element={<Services greek={greek}/>} />
                <Route path='/projects' element={<Projects greek={greek}/>} />
                <Route path='/clients' element={<Clients greek={greek}/>} />
                <Route path='/contact' element={<Contact greek={greek}/>} />
            </Routes>
        </AnimatePresence>
    )
}

export default AnimatedRoutes
import React, { useRef, useState, useEffect } from "react";
import {motion} from 'framer-motion';
import loading from "../images/loading.png";
import "./VideoThumbnail.css";

const VideoThumbnail = ({ videoPath }) => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [thumbnail, setThumbnail] = useState(null);

  useEffect(() => {
    if (!videoPath) return;

    const video = document.createElement("video"); // Use a dynamic video element
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    video.src = videoPath;
    video.crossOrigin = "anonymous"; // Allow cross-origin videos (if applicable)

    video.onloadedmetadata = () => {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      video.currentTime = 5; // Seek to 5 seconds (adjust as needed)
    };

    video.onseeked = () => {
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      const thumbnailUrl = canvas.toDataURL("image/png");
      setThumbnail(thumbnailUrl);
    };

    video.onerror = (e) => {
      console.error("Error loading video:", e);
    };

    // Cleanup when the component is unmounted
    return () => {
      video.src = ""; // Release video resources
    };
  }, [videoPath]);

  // Return the generated thumbnail as an <img> element
  if (thumbnail) {
    return <img src={thumbnail} alt="Video Thumbnail" className="my-image"/>;
  }

  return <img src={loading} alt="Video Thumbnail" className="loading my-image"/>; // Display a placeholder while loading
};

export default VideoThumbnail;

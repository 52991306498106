import React, { useState, useEffect } from 'react';
import './PaginationComponent.css';

const PaginationComponent = ({ totalResults, totalPages, handlePageClickParent, currentPageGiven, categoryGiven, greek }) => {
  const [currentPage, setCurrentPage] = useState(currentPageGiven ? currentPageGiven : 1);

  const handlePageClick = (p) => {
    setCurrentPage(p);
    handlePageClickParent(p);
  };

  // Reset current page when totalPages changes
  useEffect(() => {
    if(currentPage>totalPages || categoryGiven)
      setCurrentPage(1);
      handlePageClickParent(1);
  }, [totalPages]);

  const getPaginationButtons = () => {
    const pageButtons = [];
    const maxVisibleButtons = 5; // Number of buttons to show

    if (totalPages <= maxVisibleButtons) {
      for (let i = 1; i <= totalPages; i++) {
        pageButtons.push(
          <button
            key={i}
            className={`pagination-button col ${currentPage === i ? 'active' : ''}`}
            onClick={() => handlePageClick(i)}
          >
            {i}
          </button>
        );
      }
    } else {
      let startPage = Math.max(1, currentPage - 2);
      let endPage = Math.min(totalPages, currentPage + 2);

      if (currentPage <= 3) {
        startPage = 1;
        endPage = maxVisibleButtons;
      } else if (currentPage + 2 >= totalPages) {
        startPage = totalPages - maxVisibleButtons + 1;
        endPage = totalPages;
      }

      if (startPage > 1) {
        pageButtons.push(
          <button key={1} className="pagination-button col" onClick={() => handlePageClick(1)}>
            1
          </button>
        );
        if (startPage > 2) {
          pageButtons.push(<span key="start-ellipsis" className="ellipsis">...</span>);
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        pageButtons.push(
          <button
            key={i}
            className={`pagination-button col ${currentPage === i ? 'active' : ''}`}
            onClick={() => handlePageClick(i)}
          >
            {i}
          </button>
        );
      }

      if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
          pageButtons.push(<span key="end-ellipsis" className="ellipsis">...</span>);
        }
        pageButtons.push(
          <button key={totalPages} className="pagination-button col" onClick={() => handlePageClick(totalPages)}>
            {totalPages}
          </button>
        );
      }
    }

    return pageButtons;
  };

  return (
    <div>
      {/* Render Pagination Buttons */}
      <div className='h5 fw-light'>{!greek?"Results":"Αποτελέσματα"}: {totalResults}</div>
      <div className="pagination d-flex">
        {getPaginationButtons()}
      </div>
    </div>
  );
};

export default PaginationComponent;

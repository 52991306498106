import React, { useState } from 'react';
import "./SearchBarComponent.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass} from '@fortawesome/free-solid-svg-icons';

const SearchBar = ({ onSearch, greek }) => {
  const [query, setQuery] = useState('');

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSearch = () => {
    onSearch(query);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className="search-bar">
        <div className="input-group">
        <input id="input-search-field" style={{"width":"60%", "borderRadius":"0px", "margin":"auto"}} type="text" className="form-control" placeholder={`${!greek?"Search projects...":"Αναζήτηση στα έργα..."}`} onChange={handleInputChange} onKeyDownCapture={handleKeyPress}/>
        <div className="input-group-text btn btn-dark" onClick={handleSearch}><FontAwesomeIcon icon={faMagnifyingGlass} size='1x' /></div>
        </div>
    </div>
  );
};

export default SearchBar;

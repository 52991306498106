// Contact.js
import React, { useState } from 'react';
import {motion} from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faMapLocation, faMapLocationDot} from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons';
import './Contact.css';
import MapComponent from './MapComponent';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Contact({greek}) {
  const [isHovered, setIsHovered] = useState(false);
  const handleCopyPhone = (e)=>{
    const phoneNumber = e.target.textContent.trim(); // Get value from input field

    navigator.clipboard.writeText(phoneNumber).then(() => {
      console.log('Text copied to clipboard');
      toast.success(greek?"Ο αριθμός τηλεφώνου '" + phoneNumber + "' αντιγράφηκε στο πρόχειρο.":"Phone number '" + phoneNumber + "'  copied to clipboard.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        });
      // toast(greek?"Ο αριθμός τηλεφώνου '" + phoneNumber + "' αντιγράφηκε στο πρόχειρο.":"Phone number '" + phoneNumber + "'  copied to clipboard.");
    }).catch(err => {
        console.error('Failed to copy text: ', err);
    });
  }

  const handleCopyEmail = (e) => {
    const email = e.target.textContent.trim(); // Get value from input field

    navigator.clipboard.writeText(email).then(() => {
      console.log('Text copied to clipboard');
      toast.success(greek?"Η διεύθυνση ηλεκτρονικού ταχυδρομείου '" + email + "' αντιγράφηκε στο πρόχειρο.":"Email '" + email + "'  copied to clipboard.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        });

      // toast(greek?"Η διεύθυνση ηλεκτρονικού ταχυδρομείου '" + email + "' αντιγράφηκε στο πρόχειρο.":"Email '" + email + "'  copied to clipboard.");
    }).catch(err => {
        console.error('Failed to copy text: ', err);
    });
  }
  
  return (
    <div>
    <ToastContainer />
    <div className={`blackOverlay ${isHovered ? 'darkened' : ''}`}/>
    <div className="pageBody"/>
    
    <br/> 
    <div className='scroll'>
    <motion.div initial={{opacity:0, transition: { duration:0.2, ease: "easeInOut" }}} animate={{opacity:1, transition: { duration:1, ease: "easeInOut", delay:0.4 }}} exit={{opacity:0, transition: { duration:0.2, ease: "easeInOut" }}}>
  
        <motion.div initial={{x:"30vw"}} animate={{x:0  ,transition: { duration: 0.6, ease: "backInOut", delay:0.6 }}} className='contact-title'>
          <div className='h1 fw-light text-decoration'>{!greek?"Contact us":"Επικοινωνήστε μαζί μας"}</div>
          <hr/>
        </motion.div>
      
          <motion.div initial={{"opacity":0}} animate={{"opacity":100  ,transition: { duration: 1, ease: "easeInOut", delay:0.4 }}}>
          <div className='contact-info'>
           
          
            <motion.div initial={{x:"-30vw"}} animate={{x:0  ,transition: { duration: 0.6, ease: "backInOut", delay:0.6 }}}>
            <div className='contact-option' onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
              <FontAwesomeIcon icon={faFacebook} size='2x' />
              <div className='h3 fw-light text-decoration'> <a href="https://www.facebook.com/eleni.kourkounaki" rel="noreferrer" target="_blank" className='link'>/eleni.kourkounaki</a></div>
              <span className='h5 fw-light'>{!greek?"(οpens new window to our facebook page)":"(ανοίγει νέα καρτέλα στην σελίδα μας στο facebook)"}</span>
            </div>
            </motion.div>
           
            <motion.div initial={{x:"-30vw"}} animate={{x:0  ,transition: { duration: 0.6, ease: "backInOut", delay:0.7 }}}>
            <div className='contact-option' onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
              <FontAwesomeIcon icon={faEnvelope} size='2x' />
              <div className='h3 fw-light text-decoration' onClick={handleCopyEmail} style={{"cursor":"pointer"}}>kourkounaki@me.com </div>
              <span className='h5 fw-light'>{!greek?"(copy to clipboard)":"(αντιγραφή στο πρόχειρο)"}</span>
            </div>
            </motion.div>
         
            <motion.div initial={{x:"-30vw"}} animate={{x:0  ,transition: { duration: 0.6, ease: "backInOut", delay:0.8 }}}>
            <div className='contact-option' onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
              <FontAwesomeIcon icon={faPhone} size='2x' className='col-1' />
              <div className='h3 fw-light text-decoration' onClick={handleCopyPhone} style={{"cursor":"pointer"}}>+30 6977458754</div>
              <div className='h3 fw-light text-decoration' onClick={handleCopyPhone} style={{"cursor":"pointer"}}>+30 2121217231</div>
              <span className='h5 fw-light'>{!greek?"(copy to clipboard)":"(αντιγραφή στο πρόχειρο)"}</span>
            </div>
            </motion.div>
          
            <motion.div initial={{x:"-30vw"}} animate={{x:0  ,transition: { duration: 0.6, ease: "backInOut", delay:0.9 }}}>
            <div className='contact-option' onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
              <FontAwesomeIcon icon={faMapLocationDot} size='2x' />
              <div className='h3 fw-light text-decoration'> <a href="https://maps.app.goo.gl/HE4hCnXAPwY6ttwD7" rel="noreferrer" target="_blank" className='link'>{!greek?"Google Maps":"Google Maps"}</a></div>
              <span className='h5 fw-light'>{!greek?"(οpens new window to our google maps pin)":"(ανοίγει νέα καρτέλα στο στίγμα μας στο google maps)"}</span>      
            </div>
            </motion.div>
            
            <motion.div initial={{x:"-30vw"}} animate={{x:0  ,transition: { duration: 0.6, ease: "backInOut", delay:1 }}}>
            <div className='contact-option contact-map' onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
              <FontAwesomeIcon icon={faMapLocation} size='2x' style={{"cursor":"default"}}/>
              <div className='h3 fw-light text-decoration' style={{"cursor":"pointer"}}> {!greek?"Our offices location":"Τα γραφεία μας"}</div>
              <div className='h5 fw-light text-decoration' style={{"cursor":"pointer"}}> {!greek?"El. Venizelou 2, Agia Paraskevi":"Ελ. Βενιζέλου 2, Αγία Παρασκευή"} ,15341</div>
              <div className='d-flex map-component'>
              <MapComponent />
              </div>
           
            </div>
            </motion.div> 

            <br/>
          </div>
          </motion.div>
       
    
      <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
    </motion.div>
    </div>


    </div>
  );
}

export default Contact;
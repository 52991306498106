//import logo from './logo.svg';
import './App.css';
import React, { Component, useTransition } from 'react';
import { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import 'bootstrap/dist/js/bootstrap.bundle';
import AnimatedRoutes from './components/AnimatedRoutes';

  


function App() {
  const [greek,setGreek] = useState(false);
  const handleGreek = () => {
    setGreek((prev)=>!prev);
  };
  return (
    <Router>
      <div className="App">
        <div>
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN" crossOrigin="anonymous"/>
        </div>
        <div>
   
          <Navbar greek={greek} handleGreek={handleGreek}/>
          <AnimatedRoutes greek={greek}/>
          <Footer greek={greek}/>
        
          <script src="https://code.jquery.com/jquery-3.7.1.min.js"></script>
          <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-C6RzsynM9kWDrMNeT87bh95OGNyZPhcTNXj1NW7RuBCsyN/o0jlpcV8Qyq46cDfL" crossOrigin="anonymous"></script>
        
        </div>

        
    </div> 
    </Router>
  );
}

export default App;

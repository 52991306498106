// Bio.js

import React from 'react';
import {motion} from 'framer-motion';
import "./Clients.css";

function importAllImages(r) {
  return r.keys().map(r);
}
// Dynamically import all images from a directory
const images = importAllImages(require.context('../images/clients', false, /\.(png|jpe?g|svg)$/));




function Clients({greek}){

    //const clientLogos = [clieant1,clieant2,clieant3,clieant4,clieant5,clieant6,clieant7,clieant8,clieant9,clieant10,clieant11];
    const clientLogos = images;

    function getRandomFloat(min, max, decimals) {
      const str = (Math.random() * (max - min) + min).toFixed(
        decimals,
      );
    
      return parseFloat(str);
    }

    return (
        <div>
          <div className="pageBody"/>
          <br/> <br/>
          <motion.div initial={{opacity:0}} animate={{opacity:100, transition: { duration: 0.8, delay:0.7 , ease: "easeInOut"}}} exit={{opacity:0, x:'-100%', transition: { duration: 0.2, ease: "easeInOut" }}}>
   
          <div className='row'>
          <motion.div initial={{x:"30vw", opacity:0}} animate={{x:0,opacity:100  ,transition: { duration: 0.8, ease: "backInOut", delay:0.5 }}} exit={{opacity:0,transition: { duration: 0.3, ease: "easeInOut"}}}>
                <div className='h4 fw-light text-decoration'>{!greek?"Our clients satisfaction is our highest priority!":"Η ικανοποίηση των πελατών μας έχει πάντοτε προτεραιότητα!"}</div>
          </motion.div>
          <motion.div initial={{x:"-30vw", opacity:0}} animate={{x:0,opacity:100 ,transition: { duration: 0.8, ease: "backInOut", delay:0.5 }}} exit={{opacity:0,transition: { duration: 0.3, ease: "easeInOut"}}}>
                <div className='h5 fw-light text-decoration'>{!greek?"Here are some of them:":"Μερικοί από αυτούς είναι:"}</div>
          </motion.div>
        
          </div>

          <div className='row justify-content-center'>
            {
              clientLogos.map((photo, index) => {
                var i = getRandomFloat(0.1,0.5,1);
                var j = getRandomFloat(0.1,1,1);
                return(
                   
                    <motion.div className='col-md-3 col-sm-4 mt-3 mb-3 ms-1 me-1 justify-content-center client-container'
                    initial={{sopacity:1, rotateX:90,rotateZ:'0deg',scale:1, y:'00vh', x:'0vw', transition: { duration: 0.1}}} animate={{opacity:100,rotateX:0,rotateZ:'0deg',scale:1, y:'0%', x:'0%', transition: { duration: 0.8, delay:i*2+1 , ease: "backOut"}}} exit={{opacity:0, x:`-50vw`, scale:0, rotate:50*i*j>0.5?1:-1,rotateZ:"20deg", transition: { duration: 0.3, delay:i, ease: "easeIn" }}}
                    >
                      <div className='client-card'>
                        <img className='client-logo' src={photo} key={`photo_shortcut_${index}`} type="button" data-bs-target="#carousel" data-bs-slide-to={index} aria-current={`page`} aria-label={`Slide ${index}`}></img>
                      </div>
                    </motion.div>
                    
                    )})
            }
          </div>
          
          <br/>
         </motion.div>
      </div>
    );
  }
export default Clients;
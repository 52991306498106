// Footer.js

import React, { Component } from 'react';
import bg from "../images/backgrounds/footer.webp";
import "./Footer.css";

class Footer extends Component {
  render() {
    return (
        <div>
            <div className="row align-items-center Footer foot">
                <div className='col fw-light'>ek-designs.gr</div>
                <div className='col fw-light'>Copyright© 2023-2024 </div>
            </div>
            <div className="row align-items-center">
                {/* <img src={bg} class="img-fluid" alt="..."/> */}
            </div>
        </div>
    );
  }
}

export default Footer;
import React from 'react';
import {motion, AnimatePresence} from 'framer-motion';
import { useState, useEffect, useRef } from 'react';
import './Projects.css';
import Project from './Project.js';
import PaginationComponent from './PaginationComponent.js';
import SearchBar from './SearchBarComponent.js';
import { useSearchParams } from 'react-router-dom';
import notFound from '../images/not-found.jpg';
import VideoThumbnail from './VideoThumbnail.js';


function Projects({greek}){
  const isMounted = useRef(1);

  const [popup,setPopup] = useState(false);
  const [loading, setLoading] = useState(true); // Track whether clients are loading

  const [projects,setProjects] = useState();
  const [projectItems,setProjectItems] = useState([]);

  const [selectedProject,setSelectedProject] = useState(null);

  const [searchParams] = useSearchParams(); 


// CATEGORIES STUFF
  const categories = [
    {"en":"All", "gr":"Όλα"},
    {"en":"Branding", "gr":"Εταιρική Ταυτότητα"},
    {"en":"Books", "gr":"Βιβλία"},
    {"en":"Brochures", "gr":"Έντυπα"},
    {"en":"Posters", "gr":"Αφίσες"},
    {"en":"Advertising", "gr":"Καταχωρίσεις"},
    {"en":"Packaging", "gr":"Συσκευασίες"},
    {"en":"Promotional", "gr":"Προωθητικά είδη"},
    {"en":"Invitations", "gr":"Προσκλήσεις"},
    {"en":"Outdoor Design", "gr":"Εξωτερική Διαφήμιση"},
    {"en":"Car Wrapping", "gr":"Ντύσιμο Οχημάτων"},
    {"en":"Media & Animation", "gr":"Media & Animation"},
    {"en":"Social", "gr":"Social"},
    {"en":"Exhibition Design", "gr":"Σχεδιασμός Εκθεσιακών Περιπτέρων"}
  ]

  const findCategoryIndexByEnglishName = (englishName) => {
    for (let i = 0; i < categories.length; i++) {
      // console.log("in loop categories[i].en.toUpperCase()=", categories[i].en.toUpperCase() , "englishName=", englishName);
        if (categories[i].en.toUpperCase() === englishName.toUpperCase()) {
            return i;
        }
    }
    console.error("Category not found -", englishName);
    return -1; // If category is not found
  }

  const getCategory = (index) => {
    if (index < categories.length && index >= 0){
        if (!greek)return categories[index].en;
        else return categories[index].gr;
    }
    else if(index===-1){
        console.error("Category array index not found");
    }
    else{
        console.error("Category array index out of bounds");
    }
  }

  const getEnglishCategory = (index) => {
    if (index < categories.length && index >= 0){
        return categories[index].en;
    }
    else if(index===-1){
        console.error("Category array index not found");
    }
    else{
        console.error("Category array index out of bounds");
    }
  }

  const [category,setCategory] = useState("ALL");
  const categoryRef = useRef("ALL");
// --

  const [clients, setClients] = useState([]);
  const [client,setClient] = useState("-");
  const clientRef = useRef("-");

  const [page,setPage] = useState(1);
  const pageRef = useRef(1);
  const [totalPages,setTotalPages] = useState(0);
  const [totalItems,setTotalItems] = useState(0);

  
  const [filterToggle,setFilterToggle] = useState(true);

  const [search,setSearch] = useState('');
  const searchRef = useRef('');


  const handleBack = () =>{
    setPopup(false);
  }
  handleBack.bind(this);



  const handleFilterToggle = () =>{
    setFilterToggle((old)=>!old);
  }

  const handlePagination = (p) =>{
    setPage(p);
    pageRef.current = p;
    // console.log("pagination func", p);
  }

  const handleSearch = (q) =>{
    setSearch(q);
    searchRef.current = q;
  
    setPage(1);
    pageRef.current = 1;
  }

  const handleClickProject = (p) =>{
    setSelectedProject(p);
    setPopup(true);
  }


  const handleCategoryFilter = (e) =>{
    setCategory(e.target.value);
    categoryRef.current = e.target.value.toUpperCase();

    setPage(1);
    pageRef.current = 1;
    
  }


  const handleClientFilter = (e) =>{
    setClient(e.target.value);
    clientRef.current = e.target.value;

    setPage(1);
    pageRef.current = 1;
  }


  function getRandomFloat(min, max, decimals) {
    const str = (Math.random() * (max - min) + min).toFixed(
      decimals,
    );
  
    return parseFloat(str);
  }



  useEffect(() => {
    fetch('https://ek-creative-backend-api.onrender.com/projects/clients')
      .then(response => response.json())
      .then(data => {
        setClients(data.data);
        setLoading(false); // Set loading to false when clients are fetched
      })
      .catch(error => console.error('Error fetching clients:', error));
  }, []);



  useEffect(() => {
    if(searchParams.get('client')){
      setClient(searchParams.get('client'));
      clientRef.current = searchParams.get('client')?searchParams.get('client'):"-";
    }
    if(searchParams.get('category')){
      setCategory(getEnglishCategory(findCategoryIndexByEnglishName(searchParams.get('category'))));
      categoryRef.current = getEnglishCategory(findCategoryIndexByEnglishName(searchParams.get('category'))).toUpperCase();
    }
  }, []);



  useEffect(() => { 
    if (isMounted.current > 1) {
    // console.log("IN USEFFECT of projects");
      
      const items = projects?.map((project,index) => {
        var i = getRandomFloat(0.1,0.5,1);
        var j = getRandomFloat(0.1,1,1);
        return( 
          project.categories.includes(categoryRef.current) || categoryRef.current==="ALL"?
            <motion.div key={`project_${project.id}`} className='fw-light mycard d-flex'  initial={{opacity:0, rotate:-150*i*(j>0.5)?1:-1,rotateZ:'90deg',scale:0, y:'30vh', x:'50vw', transition: { duration: 0.1}}} animate={{opacity:100,rotate:0,rotateZ:'0deg',scale:1, y:'0%', x:'0%', transition: { duration: 0.8, delay:i+0.5 , ease: "backOut"}}} exit={{opacity:0, x:`-50vw`, scale:0, rotate:50*i*j>0.5?1:-1,rotateZ:"20deg", transition: { duration: 0.3, delay:i, ease: "easeIn" }}}>
              <div key={`project_${project.id}_card${index}`} onClick={()=>handleClickProject(project)} className="card">
              {(project.photos[0].path &&
              !(
                project.photos[0].path.endsWith('.mp4') ||
                project.photos[0].path.endsWith('.avi') ||
                project.photos[0].path.endsWith('.mov') ||
                project.photos[0].path.endsWith('.mkv') // Add other formats as needed
              ))?<img key={`project_${project.id}_img${index}`} src={ project.photos[0].path } className="card-img-top" alt="..."/>
              :
              <VideoThumbnail videoPath={project.photos[0].path} />
              }
                <h5 key={`project_${project.id}_name${index}`}  className='fw-light text-body-primary mx-auto mb-0 h4'> {project.name} </h5> <div style={{"display":"inline-block"}} className='text-body-primary mt-0'>{project.date}</div>
                <div className='card-bottom'>
                  <hr className='separator'/>
                  <div key={`project_${project.id}_row${index}`} className='row'>
                    <h5 key={`project_${project.id}_subtitle${index}`} className="card-subtitle mb-2 text-body-secondary m-auto" >
                      <div key={`project_${project.id}_subrow${index}`} className='row'>
                        {project.categories?.map((category,index2) => {
                          return(
                          <div key={`project_${project.id}_col${index2}`} className='col row' >
                            <div key={`project_${project.id}_category_${project.id}_${index2}`} className='card-categories small'>
                                {getCategory(findCategoryIndexByEnglishName(category.toUpperCase()))}
                            </div>
                          </div>)})
                        }
                      </div>
                    </h5>
                  </div>
                </div>
              </div>
            </motion.div>
            :
            <motion.div key={`project_${project.id}`} className='h3 fw-light text-decoration mycard d-flex'  initial={{opacity:0, rotate:-100*i,scale:0, y:'100%', x:'100%', transition: { duration: 0.1}}} animate={{opacity:100,rotate:0,scale:1, y:'0%', x:'0%', transition: { duration: 0.7, delay:i , ease: "backInOut"}}} exit={{opacity:0, x:`-100%`, scale:0, rotate:50*i, transition: { duration: 0.5,delay:i, ease: "easeInOut" }}}>
              <div key={`project_${project.id}_card${index}`} onClick={()=>handleClickProject(project)} className="card">
              </div>
            </motion.div>
        )
      });

      setProjectItems(items);
    }
    else {
      isMounted.current ++;
    }
  // eslint-disable-next-line
  }, [projects]);









  useEffect(() => {
    // console.log("categoryref=", categoryRef.current);
    var apiUrl = "https://ek-creative-backend-api.onrender.com/projects"; //base url

    apiUrl += `?page=${pageRef.current}`; //page

    if (categoryRef.current!=="ALL") { //category
      apiUrl += `&category=${categoryRef.current}`;
    }
    if (clientRef.current!=="-") { //client
      apiUrl += `&client=${clientRef.current}`;
    }
    if (searchRef.current!=='') { //search
      apiUrl += `&search=${searchRef.current}`;
    }
   
    // console.log("api url is:", apiUrl);
    fetch(apiUrl)
      .then(res => res.json())
      .then((data) => {
        var projectData = data.data.map(project => ({
          ...project,
          categories: JSON.parse(project.categories),
          photos: JSON.parse(project.photos),
        }));
        setProjects(projectData); 
        setTotalPages(data.pagination.totalPages);
        setTotalItems(data.pagination.totalItems);
      })
      .catch(rejected => {
        console.log(rejected);
      });
  // eslint-disable-next-line
  },[page, category, client, search, greek]); 


  
    return (
      <div>
      <div className='pageBody'/>
      <motion.div initial={{opacity:0}} animate={{opacity:100, transition: { duration: 0.5, delay:0.1 , ease: "easeInOut"}}} exit={{opacity:0,scale:0, transition: { duration: 0.6, ease: "backInOut" }}}>
        
{/* START OF POPUP OR LIST */}
          {!popup?
          <AnimatePresence>
          <motion.div className='container-fluid scroll' initial={{x:"-30vw", "opacity":0}} animate={{x:0, "opacity":1, transition: { duration: 1, delay:0.6 , ease: "backOut"}}} exit={{opacity:0, transition: { duration: 0.5, ease: "easeInOut" }}}>
              <br/><br/>
              <div className='filter-div'>
                <SearchBar onSearch={handleSearch} greek={greek}/>
                <div className='filter-div-title'>
                  <PaginationComponent currentPageGiven={page} handlePageClickParent={handlePagination} totalPages={totalPages} totalResults={totalItems} categoryGiven={category} greek={greek}/>
                  <hr/>
                  <div className='row filters-btn'>
                    <div className={`h4 fw-light`} onClick={handleFilterToggle}>{!greek?"Filters":"Φίλτρα"} <div className={`regular ${filterToggle?'':'rotated'}`} style={{"marginBottom":"0.5rem"}}>🡑</div></div>
                  </div>
                  
                </div>
               
                <div className={`filter-div-body ${!filterToggle&&"closed"}`}>
                <br/>
                  <hr style={{"width":"70%", "marginBottom": "-20px", "margin":"auto"}}/>
                  
                  <div className='fw-light text-decoration'>{!greek?"Category":"Κατηγορία"}</div>
                  <select key="categoryField" id='categorySelectField' className={`form-select btn btn-dark categories-button ${categoryRef.current!=='ALL'?"enabled":"not-enabled"}`} aria-label="select category" onChange={handleCategoryFilter} style={{ textTransform: "none" }} value={category}>
                    {categories.map((category,index)=>{return(
                      <option name={greek?category.gr:category.en} 
                        value={category.en}
                        className={`my-opt ${categoryRef.current===category.en?"enabled":"not-enabled"}`}
                        key={`category_option_${index}`} 
                        id={`category_${index}`}
                        >{greek?category.gr:category.en}
                        </option> 
                    )})} 
                  </select>
                  <br/>
                  <div className='fw-light text-decoration'>{!greek?"Client":"Πελάτης"}</div>
                    <select key="clientField" id='clientSelectField' className={`form-select btn btn-dark categories-button ${clientRef.current!=='-'?"enabled":"not-enabled"}`} aria-label="select client" onChange={handleClientFilter} style={{ textTransform: "none" }}>
                      { loading ? (
                          <option>Loading clients...</option>
                        ) : (
                          clients.map((client,index) => (
                            <option 
                              style={{"width":"50%", "margin":"auto"}}
                              name={client} 
                              value={client} 
                              className={`my-opt ${clientRef.current===client?"enabled":"not-enabled"}`}
                              key={`client_option_${index}`} 
                              id={`client_${index}`}
                            >
                              {client}
                            </option> 
                          ))
                        )
                      } 
                    </select>
                  </div>
              </div>
                

              {/* <br key={`brake`}/><br key={`brake2`}/> */}
             
              <motion.div className={`cardContainer row`} initial={{opacity:0}} animate={{opacity:100, transition: { duration: 0.5, delay:0.5 , ease: "easeInOut"}}} exit={{opacity:0, transition: { duration: 0.3, ease: "easeInOut" }}}>
                <AnimatePresence>
                {projectItems&&projectItems.length>0?
         
                projectItems
                
                  
                :
                <motion.div key={`default-card`} className='fw-light mycard d-flex' initial={{opacity:0, rotate:-150,rotateZ:'90deg',scale:0, y:'30vh', x:'50vw', transition: { duration: 0.1}}} animate={{opacity:100,rotate:0,rotateZ:'0deg',scale:1, y:'0%', x:'0%', transition: { duration: 0.8, delay:0.8 , ease: "backOut"}}} exit={{opacity:0, x:`-50vw`, scale:0, rotate:50,rotateZ:"20deg", transition: { duration: 0.3, delay:0.2, ease: "easeIn" }}}>
                <div className="card">
                  <img src={notFound} className="card-img-top" style={{ objectFit: 'scale-down' }} alt="..."/>
                  <div className='h4 fw-light text-decoration text-body-primary'> No projects found... </div>
                  <hr/>
                  <div className='row'>
                    <h6 className="card-subtitle mb-2 text-body-secondary">
                      <div className='row justify-content-center'>
                          <div className='col'>
                            <div className='card-categories'>
                                Try searching using a different filter
                            </div>
                          </div>
                      </div>
                    </h6>
                  </div>
                </div>
                </motion.div>
                }
                </AnimatePresence>
              </motion.div> 
       
              <br/><br/><br/><br/><br/>
            </motion.div>
            </AnimatePresence>
          :
          <AnimatePresence>
          <motion.div key='project' initial={{opacity:0}} animate={{opacity:100, transition: { duration: 0.5, delay:0.5 , ease: "easeInOut"}}} exit={{opacity:0, scale:0,y:"50%", transition: { duration: 0.5, ease: "backIn" }}}>
            <Project greek={greek} project={selectedProject} handleBack={handleBack} />
          </motion.div>
          </AnimatePresence>
          }
{/* END OF POPUP OR LIST */}
      <br/>
      </motion.div> 
      </div>
      
      );
  }
export default Projects;